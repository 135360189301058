import React, { useContext, useState, useEffect, useRef } from 'react';
import { LatestContentContext } from '../components/LatestContentContext';
import ContentItem from '../components/ContentItem';
import AwardItem from '../components/AwardItem';
import Sidebar from '../components/Sidebar';
import SiteMeta from '../components/SiteMeta';

const LatestContent = () => {
  const { posts = [], awards = [], team = [] } = useContext(LatestContentContext); // Removed pages from context
  const [isMuted, setIsMuted] = useState(true);
  const scrollItemRefs = useRef([]);
  const [sortedItems, setSortedItems] = useState([]); // State to store sorted items
  const [visibleItems, setVisibleItems] = useState([]); // Store items to be displayed
  const [itemCount, setItemCount] = useState(0); // Track how many items have been displayed

  // Combine items and sort by date_created
  useEffect(() => {
    const headerItem = posts.find((post) => post.is_header) || null; // Find the header item
    const allItems = [
      ...posts.filter(post => !post.is_header).map(item => ({ ...item, type: 'posts' })),
      ...awards.map(item => ({ ...item, type: 'awards' })),
      ...team.map(item => ({ ...item, type: 'team' })),
    ];

    // Sort items by date_created in descending order (newest first)
    const sortedAllItems = allItems.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

    // Combine the header and sorted items
    const combinedItems = headerItem ? [{ ...headerItem, type: 'posts' }, ...sortedAllItems] : sortedAllItems;

    setSortedItems(combinedItems); // Set sorted items
    setVisibleItems(combinedItems.slice(0, 5)); // Show the first 5 items initially
    setItemCount(5); // Set initial visible item count to 5
  }, [posts, awards, team]); // Removed pages from the dependency array

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.classList.contains('scroll-item')) {
            entry.target.classList.add('current'); // Add the "current" class

            if (entry.target === scrollItemRefs.current[scrollItemRefs.current.length - 1]) {
              generateMoreItems(); // Load more items when the last one comes into view
            }

            // Load media when it comes into view
            const mediaElement = entry.target.querySelector('video, img');
            if (mediaElement) {
              const dataSrc = mediaElement.getAttribute('data-src');
              if (dataSrc) {
                mediaElement.src = dataSrc; // Set the source for the video or image
                mediaElement.removeAttribute('data-src'); // Remove the attribute once loaded
              }
            }
          } else {
            entry.target.classList.remove('current'); // Remove "current" class if not in view
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observe all current items
    scrollItemRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    // Cleanup observer
    return () => {
      scrollItemRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [visibleItems]);

  const generateMoreItems = () => {
    // Avoid generating items if sortedItems is empty (which shouldn't happen)
    if (sortedItems.length === 0) return;

    // Generate two more items from the sorted list
    const newItems = [];
    for (let i = 0; i < 2; i++) {
      const index = (itemCount + i) % sortedItems.length; // Loop through the data
      newItems.push(sortedItems[index]);
    }

    // Append new items to the visible list
    setVisibleItems(prev => [...prev, ...newItems]);
    setItemCount(prevCount => prevCount + 2); // Update the item count
  };

  const handleMute = () => {
    setIsMuted((prev) => !prev);
  };

  const handleScrollUp = () => {
    const currentElement = document.querySelector('.scroll-item.current');
    
    if (currentElement && currentElement.previousElementSibling) {
      currentElement.previousElementSibling.scrollIntoView({ behavior: 'smooth' });
      currentElement.classList.remove('current');
      currentElement.previousElementSibling.classList.add('current');
    } else {
      console.log('Already at the first item.');
    }
  };

  const handleScrollDown = () => {
    const currentElement = document.querySelector('.scroll-item.current');
    
    if (currentElement && currentElement.nextElementSibling) {
      currentElement.nextElementSibling.scrollIntoView({ behavior: 'smooth' });
      currentElement.classList.remove('current');
      currentElement.nextElementSibling.classList.add('current');
    } else {
      console.log('Already at the last item.');
    }
  };

  const renderContent = (item) => {
    if (!item) return null;

    // Render the normal content item
    switch (item.type) {
      case 'posts':
        return <ContentItem key={item.id} item={item} isMuted={isMuted} onMute={handleMute} />;
      case 'awards':
        return <AwardItem key={item.id} item={item} isMuted={isMuted} onMute={handleMute} />;
      default:
        return null;
    }
  };

  return (
    <>
      <SiteMeta />
      <div className="home-page top-screen pdc">
        <div className="scroll-container" style={{ minHeight: '100vh' }}>
          <Sidebar
            onScrollUp={handleScrollUp}
            onScrollDown={handleScrollDown}
            onMute={handleMute}
            isMuted={isMuted}
          />
          {visibleItems.map((item, index) => (
            <div
              className="scroll-item"
              key={`${item.id}-[${index}]`} // Use item.id and index for a unique key
              ref={(el) => (scrollItemRefs.current[index] = el)}
            >
              {renderContent(item)}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LatestContent;
