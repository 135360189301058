import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import homeIcon from '../assets/img/home-icon.png';
import latestIcon from '../assets/img/latest-icon.png';
import shareIcon from '../assets/img/share-icon.png';
import backArrow from '../assets/img/back-arrow.png';

const Sidebar2 = ({ className = '' }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isLatestContent = location.pathname === '/latest';
  const isHomeContent = location.pathname === '/';
  const isTeamPage = location.pathname === '/team'; // Check if the current path is '/team'

  const currentUrl = window.location.href;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: 'Check this out!',
          url: currentUrl,
        });
        console.log('Shared successfully');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.log('Web Share API is not supported in this browser.');
    }
  };

  return (
    <div className={`sidebar type2 ${className}`}>
      {!isTeamPage && ( // Conditionally render the "Back" button if not on the '/team' page
        <button className="sidebar-button" onClick={() => navigate(-1)}>
          <img src={backArrow} alt="Back" />
          
        </button>
      )}
      <Link to="/" className="sidebar-button">
        <img src={homeIcon} alt="Home" />
        {/* <span style={{ fontWeight: isHomeContent ? 'bold' : 'normal' }}>Home</span> */}
      </Link>

      <Link to="/latest" className="sidebar-button">
        <img src={latestIcon} alt="Latest Content" />
        {/* <span style={{ fontWeight: isLatestContent ? 'bold' : 'normal' }}>Latest</span> */}
      </Link>

      <button className="sidebar-button" onClick={handleShare}>
        <img src={shareIcon} alt="share" />
        
      </button>
    </div>
  );
};

export default Sidebar2;
