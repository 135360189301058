// ContentItem.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import loadingGif from '../assets/img/loading.gif';
import ArrowLeft from '../assets/img/arrow-icon-left.png';
import ArrowRight from '../assets/img/arrow-icon-right.png';

const ContentItem = ({ item, isMuted, onMute, linkText }) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState({});
  const videoRefs = useRef([]);
  const startX = useRef(0);

  // Define the base URL for your cloudfront CDN
  const cloudfrontUrl = process.env.REACT_APP_DATA_CLOUDFRONT_URL;

  // Function to generate media URL for images or videos using filename_disk
  const getMediaUrl = (filenameDisk) => `${cloudfrontUrl}${filenameDisk}`;

  // Construct media URLs based on the fields in your data
  const mediaUrls = [
    ...(Array.isArray(item.images) ? item.images.map(image => getMediaUrl(image.directus_files_id.filename_disk)) : []),
    ...(item.video ? [getMediaUrl(item.video.filename_disk)] : []),
  ];

  // Construct the detail page link using categorySlug if available
  const detailPageLink = item ? `/${item.category?.slug || 'uncategorized'}/${item.slug}` : '#';

  // Extract YouTube video ID from youtube_url
  const getYouTubeVideoId = (url) => {
    const match = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n]{11})/);
    return match ? match[1] : null;
  };

  // If no images or videos, but youtube_url exists, use the fallback image
  const youtubeImageUrl = item.youtube_url ? `https://i3.ytimg.com/vi/${getYouTubeVideoId(item.youtube_url)}/maxresdefault.jpg` : null;

  const handlePrev = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex === 0 ? mediaUrls.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex === mediaUrls.length - 1 ? 0 : prevIndex + 1));
  };

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    if (startX.current - endX > 50) {
      handleNext();
    } else if (endX - startX.current > 50) {
      handlePrev();
    }
  };

  const handleVideoLoad = (index) => {
    setVideoLoaded((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const videoElement = videoRefs.current[entry.target.dataset.index];
          if (entry.isIntersecting && videoElement) {
            videoElement.play().catch((error) => {
              console.error('Error playing video:', error);
            });
            videoElement.muted = isMuted;
          } else if (videoElement) {
            videoElement.pause();
            videoElement.muted = true;
          }
        });
      },
      { threshold: 0.5 }
    );

    videoRefs.current.forEach((videoElement) => {
      if (videoElement) {
        observer.observe(videoElement);
      }
    });

    return () => {
      videoRefs.current.forEach((videoElement) => {
        if (videoElement) {
          observer.unobserve(videoElement);
        }
      });
    };
  }, [isMuted]);

  // Render fallback if no media is available
  if (!item || (mediaUrls.length === 0 && !item.campaign_logo && !item.youtube_url)) {
    return null;
  }

  const hasCampaignData = item.campaign_logo?.filename_disk && item.campaign_name?.trim();

  // Function to render media based on URL and index
  const renderMedia = (url, index, isVideo) => {
    if (isVideo) {
      return (
        <>
          {!videoLoaded[index] && <img src={loadingGif} alt="Loading..." className="fullscreen-media" />}
          <video
            data-index={index}
            src={url}
            autoPlay
            muted={true}
            controls={false}
            preload="auto"
            loop
            playsInline
            disablePictureInPicture
            controlsList="nodownload"
            className="fullscreen-media"
            onLoadedData={() => handleVideoLoad(index)}
            ref={(el) => (videoRefs.current[index] = el)}
            style={{ display: videoLoaded[index] ? 'block' : 'none', pointerEvents: 'none' }}
          />
          <button onClick={onMute} className="mute-unmute-button">
            {isMuted ? 'Unmute' : 'Mute'}
          </button>
        </>
      );
    } else {
      return <img src={url} alt={item.title} className="fullscreen-media" />;
    }
  };

  // If only one media item exists, display it directly without a carousel
  if (mediaUrls.length === 1) {
    return (
      <div className="content-item">
        <div className="single-media">
          {mediaUrls.length > 0 ? renderMedia(mediaUrls[0], 0, !!item.video) : <img src={youtubeImageUrl} alt="YouTube Fallback" className="fullscreen-media" />}
        </div>
        <div className="content-info">
          {hasCampaignData && (
            <div className="client-tag">
              <div className="client-logo">
                <img
                  className={`${item.campaign_logo.filename_disk}-logo`}
                  src={getMediaUrl(item.campaign_logo.filename_disk)}
                  alt={`${item.campaign_name} logo`}
                />
              </div>
              <span>{item.campaign_name}</span>
            </div>
          )}
          <h2 className="content-title mb-15">{item.title}</h2>
          <Link to={detailPageLink}>
            <p className="underline text-sm">{linkText || 'See more'}</p>
          </Link>
        </div>
      </div>
    );
  }

  // Render carousel if there are multiple media items
  return (
    <div className="content-item">
      <div className="carousel" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        {mediaUrls.length > 1 && (
          <>
            <button className="carousel-control prev" onClick={handlePrev}>
              {/* &#10094; */}
              <img src={ArrowLeft} />
            </button>
            <button className="carousel-control next" onClick={handleNext}>
              {/* &#10095; */}
              <img src={ArrowRight} />
            </button>
          </>
        )}
        <div className="carousel-content" style={{ transform: `translateX(-${currentMediaIndex * 100}%)` }}>
          {mediaUrls.map((url, idx) => (
            <div className="carousel-item" key={idx}>
              {renderMedia(url, idx, !!item.video)}
            </div>
          ))}
        </div>
        <div className="carousel-dots">
          {mediaUrls.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentMediaIndex ? 'active' : ''}`}
              onClick={() => setCurrentMediaIndex(index)}
            ></span>
          ))}
        </div>
      </div>
      <div className="content-info">
        {hasCampaignData && (
          <div className="client-tag">
            <div className="client-logo">
              <img className={`${item.campaign_logo.filename_disk}-logo`} src={getMediaUrl(item.campaign_logo.filename_disk)} alt={`${item.campaign_name} logo`} />
            </div>
            <span>{item.campaign_name}</span>
          </div>
        )}
        <h2 className="content-title mb-15">{item.title}</h2>
        <Link to={detailPageLink}>
          <p className="underline text-sm">See more</p>
        </Link>
      </div>
    </div>
  );
};

export default ContentItem;
